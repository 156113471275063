export function post(url, data, type = ""){
    const xhr = new XMLHttpRequest();
    let result = [];
    xhr.open("POST", url, false);
    xhr.onreadystatechange = function() {
        if (xhr.readyState === 4) {
            if (xhr.status === 200 || xhr.status === 201){
                result = xhr.responseText;
            }
        }
    }

    if (type === "json") xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("X-Requested-With", 'XMLHttpRequest');
    if (type === "json") xhr.send(JSON.stringify(data));
    else xhr.send(data);

    return result;
}

export function form_submit(action, params) {
    const form = document.createElement('form');
    form.method = "post";
    form.action = action;

    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            const hiddenField = document.createElement('input');
            hiddenField.type = 'hidden';
            hiddenField.name = key;
            hiddenField.value = params[key];

            form.appendChild(hiddenField);
        }
    }

    document.body.appendChild(form);
    form.submit();
}

export default {
    post,

}
