import {post} from "./module/request"
import {getCookie,setCookie,deleteCookie } from "./module/cookie";
import {error_message} from "./module/message";

const loginFm = document.getElementById("login_fm"),
    userId = document.getElementById("user_id"),
    rememberMe = document.getElementById("remember_me"),
    userPassword = document.getElementById("user_password"),
    passwordAddon = document.getElementById("password_addon");

userId.value = getCookie("user_id");
if(userId.value !== ""){
    rememberMe.checked = true;
}
rememberMe.addEventListener("change", (e) => {
    if (e.target.checked) {
        setCookie("user_id", userId.value, 30);
    } else {
        deleteCookie("user_id");
    }
});

loginFm.addEventListener('submit', function (e) {
    e.preventDefault();
    const url = this.getAttribute("action");
    let data = new FormData(loginFm);
    let response = post(url, data);
    response = JSON.parse(response);
    if (response.code === "OK") {
        if (response.auth === "5" || response.auth === "3" ) {
            location.href = "/dbms/list"
        } else {
            location.href = "/dashboard"
        }

    } else {
        error_message(response.message);
    }

}, false);

passwordAddon.addEventListener("click", function (e) {
    e.preventDefault();
    if (userPassword.type === "password") {
        userPassword.type = "text";
    } else {
        userPassword.type = "password";
    }
});